<div class="container" [ngClass]="{ opened: isChatOpened() }">
    <div class="main-container">
        <div class="members-count">{{ chat.membersCount }}</div>
        <div class="last-message" *ngIf="chat.lastMessage">
            {{ formattedDate | date: 'dd/MM/yyyy' }} {{ formattedDate | date: 'HH:mm' }}
        </div>
        <div class="last-message" *ngIf="!chat.lastMessage">Aucune</div>
    </div>
    <span
        class="icon-down"
        [ngClass]="{ opened: isChatOpened() }"
        (click)="toggleChat($event)"
    ></span>

    <div class="chat-container-entry" [ngClass]="{ opened: isChatOpened() }">
        <div class="name-user-container" *ngFor="let member of chat.members">
            <span class="icon" [ngClass]="getUserIcon(member)"></span>
            <span class="name">{{ member.lastname.toUpperCase() }} {{ member.firstname }}</span>
        </div>
        <div class="name-group-container" *ngFor="let group of chat.groups">
            <div class="group-header">
                <span class="icon-groupe"></span>
                <span class="name">{{ group.name }}</span>
            </div>
            <div class="users-container" *ngFor="let user of group.users">
                <span class="icon-apprenant"></span>
                <span class="name-user"
                    >{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span
                >
            </div>
        </div>
    </div>
</div>
