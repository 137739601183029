import { Component, OnInit, Input } from '@angular/core';

import { DialogService } from '../../../services/dialog.service';

@Component({
    selector: 'app-shared-chat-entry',
    templateUrl: './shared-chat-entry.component.html',
    styleUrls: ['./shared-chat-entry.component.scss']
})
export class SharedChatsEntryComponent implements OnInit {
    @Input() chat: any;
    @Input() selected: boolean;

    openedChat: boolean;
    formattedDate: Date;

    constructor(private dialogService: DialogService) {}

    /**
     * @param {any} user Un membre du chat actuel
     * Indique l'icône à afficher selon le type et le rôle de l'utilisateur
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getUserIcon(user: any): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tuteurentreprise) {
            return 'icon-formateurexterne';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique le nom de l'auteur du chat
     * @returns {string} Le prénom et le nom de l'auteur du chat
     */
    getAuthorName(): string {
        return this.chat.owner.lastname.toUpperCase() + ' ' + this.chat.owner.firstname;
    }

    /**
     * Indique si le chat actuel est sélectionné
     * @returns {Boolean} True si le chat actuel est sélectionné, faux dans le cas contraire
     */
    isChatSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le chat actuel est ouvert
     * @returns {Boolean} True si le chat actuel est ouvert, faux dans le cas contraire
     */
    isChatOpened(): boolean {
        return this.openedChat;
    }

    /**
     * Ouvre et affiche le chat actuel
     */
    toggleChat(event: MouseEvent) {
        event.stopImmediatePropagation();
        this.openedChat = !this.openedChat;
    }

    ngOnInit() {
        this.openedChat = false;
        if (this.chat.lastMessage && this.chat.lastMessage.timecreated) {
            this.formattedDate = new Date(this.chat.lastMessage.timecreated);
        }
    }
}
