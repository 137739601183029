<div [ngClass]="{ hidden: isLoading() }">
    <app-header class="easi-header"></app-header>
    <app-flash-message></app-flash-message>
    <app-nav
        class="left-panel"
        [ngClass]="{ reduced: !isPanelOpen }"
        (chatSelected)="updateCurrentChat($event)"
        (resize)="toggleNav()"
    ></app-nav>
    <div class="main-panel" [ngClass]="{ enlarged: !isPanelOpen }">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>
<app-loading *ngIf="isLoading()"></app-loading>
