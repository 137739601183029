import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';

import { DialogService } from '../../services/dialog.service';
import { ChatService } from '../../services/chat.service';
import { MessageService } from '../../services/message.service';
import { LoginService } from 'src/app/services/login.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Chat } from 'src/app/structures/chat';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
    @Output() resize: EventEmitter<boolean> = new EventEmitter();
    @Output() chatSelected: EventEmitter<any> = new EventEmitter();

    chats: any;
    reduced: boolean;
    chatsCount: number;

    search: string;
    activeSearch: boolean;
    @ViewChild('searchElement') searchElement: ElementRef;

    selectedChat: any;

    constructor(
        private dialogService: DialogService,
        private chatService: ChatService,
        private messageService: MessageService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.reduced = false;
        this.search = '';
        this.refreshData();
        this.chatService.refreshListChats.subscribe((selectLast) => {
            this.refreshData(selectLast);
        });
        this.chatService.setAsActive.subscribe((chat) => {
            this.selectChat(chat);
        });

        this.messageService.messageSent.subscribe((event: any) => {
            for (const i in this.chats) {
                if (this.chats[i].id === event.id) {
                    this.chats[i].lastMessage.timecreated = new Date().toISOString();
                    this.chats[i].lastMessage.message = event.message;
                    this.chats.unshift(this.chats.splice(parseInt(i, 10), 1)[0]);
                }
            }
        });
        this.route.queryParams.subscribe((params) => {
            if (params['new']) {
                if (params['user']) {
                    this.dialogService.openCreateChatDialog(null, null, +params['user']);
                } else if (params['group']) {
                    this.dialogService.openCreateChatDialog(null, +params['group'], null);
                } else {
                    this.dialogService.openCreateChatDialog();
                }
            } else if (params['shared']) {
                if (params['type'] && params['id']) {
                    this.dialogService
                        .openSharedChatDialog(params['type'], params['id'])
                        .subscribe((chatReceived: Chat | undefined) => {
                            if (chatReceived) {
                                this.selectChat(chatReceived);
                                this.router.navigate(['chat', chatReceived.id]);
                            }
                        });
                }
            }
        });
    }

    refreshData(selectLast?: boolean) {
        this.chatService.getChats().subscribe((chatLists: any) => {
            this.chats = chatLists;
            this.chatsCount = chatLists.length;
            if (selectLast) {
                this.selectChat(this.chats[0]);
            }
        });
    }

    ngOnDestroy() {}

    /**
     * Sélectionne un chat à afficher dans la vue principal
     */
    selectChat(chat: any) {
        for (const i in this.chats) {
            if (this.chats[i].id === chat.id && this.chats[i].lastMessage) {
                this.chats[i].lastMessage.readOn = new Date().toISOString();
            }
        }
        this.selectedChat = chat;
        this.chatSelected.emit(chat);
    }

    isLearner() {
        return this.loginService.isLearner();
    }

    toggleReduce() {
        this.reduced = !this.reduced;
        this.resize.emit(this.reduced);
    }

    /**
     * Ouvre la fenêtre de création de chat
     */
    openCreateChatDialog() {
        this.dialogService.openCreateChatDialog();
    }

    /**
     * retourne true si l'utilisateur connecté est modérateur de la discussion
     * @param chat la discussion concernée
     */
    isModerator(chat) {
        return this.loginService.isChatModerator(chat);
    }

    toggleActiveSearch() {
        if (!this.activeSearch) {
            this.activeSearch = true;
            setTimeout(() => {
                this.searchElement.nativeElement.focus();
            });
        } else {
            setTimeout(() => {
                this.activeSearch = false;
            }, 100);
        }
    }

    isSearchEmpty() {
        if (this.search) {
            return this.search.length === 0;
        }
        return true;
    }

    refreshDataFromSearch() {
        this.refreshData();
        if (this.activeSearch && !this.search) {
            this.toggleActiveSearch();
        }
    }

    resetSearch($event: Event): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.search = '';
        this.activeSearch = false;
        this.refreshData();
    }

    userReady() {
        return !!this.loginService.getUser();
    }
}
