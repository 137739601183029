<div class="header">
    <span class="icon-message"></span>
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <div class="name">
            <mat-label class="label">Nom</mat-label>
            <input
                matInput
                class="value"
                type="text"
                placeholder="Nom de la discussion. Par défaut, liste des participants"
                [(ngModel)]="chat.name"
            />
        </div>
    </mat-form-field>
    <div class="separator"></div>
    <div class="members">
        <div class="label">Participants</div>
        <div class="users-container">
            <div class="top">
                <div class="tab-switch">
                    <div
                        class="learner-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('learner') }"
                        (click)="switchCurrentTab('learner')"
                    >
                        {{ getCurrentLearnerNumber() }}/{{ totalLearnerNumber }} Apprenants
                    </div>
                    <div
                        class="learner-group-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('learner-group') }"
                        *ngIf="showGroupTab()"
                        (click)="switchCurrentTab('learner-group')"
                    >
                        {{ getCurrentLearnerGroupNumber() }}/{{ totalLearnerGroupNumber }} Groupes
                        d'apprenants
                    </div>
                    <div
                        class="teacher-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('teacher') }"
                        *ngIf="showTeacherTab()"
                        (click)="switchCurrentTab('teacher')"
                    >
                        {{ getCurrentTeacherNumber() }}/{{ totalTeacherNumber }} Formateurs
                    </div>
                    <div
                        class="teacher-group-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('teacher-group') }"
                        *ngIf="showTeacherTab() && showGroupTab()"
                        (click)="switchCurrentTab('teacher-group')"
                    >
                        {{ getCurrentTeacherGroupNumber() }}/{{ totalTeacherGroupNumber }} Groupes
                        de formateurs
                    </div>
                </div>
                <div class="structure-container" *ngIf="isNationalAdmin()">
                    <lib-uimm-dropdown
                        [(items)]="structures"
                        (itemsChange)="updateStructure($event)"
                        [label]="'Toutes les structures'"
                        [labelWidth]="'175px'"
                        [maxWidth]="250"
                        [maxHeight]="250"
                        [design]="'boxes'"
                        [headers]="{ search: true }"
                    ></lib-uimm-dropdown>
                </div>
                <div class="search-container">
                    <div class="search-input-container">
                        <input
                            class="input-container"
                            type="text"
                            placeholder="Chercher..."
                            [(ngModel)]="searchTerm"
                            (keyup.enter)="refreshData(true)"
                        />
                        <span class="icon-search" (click)="refreshData(true)"></span>
                    </div>
                </div>
            </div>
            <div
                class="learner-group-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageLearnerGroup(false)"
                *ngIf="isCurrentTab('learner-group')"
            >
                <app-create-chat-entry
                    *ngFor="let group of learnerGroups"
                    [element]="group"
                    [public]="chat.public"
                    [(selected)]="group.selected"
                    [(role)]="group.role"
                ></app-create-chat-entry>
            </div>
            <div
                class="teacher-group-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageTeacherGroup(false)"
                *ngIf="isCurrentTab('teacher-group')"
            >
                <app-create-chat-entry
                    *ngFor="let group of teacherGroups"
                    [element]="group"
                    [public]="chat.public"
                    [(selected)]="group.selected"
                    [(role)]="group.role"
                ></app-create-chat-entry>
            </div>
            <div
                class="learner-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageLearner(false)"
                *ngIf="isCurrentTab('learner')"
            >
                <app-create-chat-entry
                    *ngFor="let learner of learners"
                    [element]="learner"
                    [public]="chat.public"
                    [(selected)]="learner.selected"
                    [(role)]="learner.role"
                ></app-create-chat-entry>
            </div>
            <div
                class="teacher-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageTeacher(false)"
                *ngIf="isCurrentTab('teacher')"
            >
                <app-create-chat-entry
                    *ngFor="let teacher of teachers"
                    [element]="teacher"
                    [public]="chat.public"
                    [(selected)]="teacher.selected"
                    [(role)]="teacher.role"
                ></app-create-chat-entry>
            </div>
        </div>
        <div class="resume-members">
            Vous avez inscrit <b>{{ getTotalCurrentLearnerGroupNumber() }}</b> groupe(s),
            <b>{{ getTotalCurrentTeacherGroupNumber() }}</b> groupe(s) de formateurs,
            <b>{{ getTotalCurrentLearnerNumber() }}</b> apprenant(s) et
            <b>{{ getTotalCurrentTeacherNumber() }}</b> formateur(s)
        </div>
        <div class="warning">
            *Attention, un chat doit obligatoirement inclure au moins un formateur (ou un
            administrateur)
        </div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="messageContainer" *ngIf="!editMode">
        <input
            class="message-input"
            type="text"
            [readonly]="!canCreateChat()"
            [ngClass]="{ disabled: !canCreateChat() }"
            (keyup.enter)="createChat()"
            [(ngModel)]="message"
            placeholder="{{ footerLabel }}"
        />
        <span class="icon-Envoyer" (click)="createChat()" *ngIf="canCreateChat()"></span>
    </div>
    <div class="messageContainer" *ngIf="editMode">
        <div class="button-edit" (click)="createChat()">{{ footerLabel }}</div>
    </div>
</div>
