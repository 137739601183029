<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Suppression de la discussion</span>
</div>
<mat-dialog-content class="content" *ngIf="!deletionDone">
    Souhaitez-vous vraiment supprimer la discussion <span class="chat-name">{{ chat.name }}</span> ?
    <br />
    Merci de confirmer.
</mat-dialog-content>

<mat-dialog-content class="content" *ngIf="deletionDone">
    La discussion {{ chat.name }} a bien été supprimée.
</mat-dialog-content>

<div class="footer" *ngIf="!deletionDone">
    <div class="cancel-button" (click)="closeDialog()">Annuler</div>
    <div class="delete-button" (click)="deleteChat(chat)">
        Supprimer
        <span class="icon-bin"></span>
    </div>
</div>
<div class="footer" *ngIf="deletionDone">
    <div class="close-button" (click)="closeDialog()">OK</div>
</div>
