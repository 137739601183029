import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
    @ViewChild('fileElement', { static: true }) fileElement: ElementRef;
    file: File;
    files: Array<File>;

    constructor(public dialogRef: MatDialogRef<UploadFilesComponent>) {}

    uploadFile() {
        this.fileElement.nativeElement.click();
    }

    onFilesAdded(file: File) {
        if (file[0]) {
            this.files.push(file[0]);
        }
    }

    deleteFile(id: number) {
        this.files.splice(id, 1);
        this.fileElement.nativeElement.value = '';
    }

    addFilesToPost() {
        if (!this.isDisabled()) {
            this.closeDialog(this.files);
        }
    }

    isDisabled() {
        return this.files.length === 0;
    }

    /**
     * Ferme la fenêtre d'upload de fichier
     */
    closeDialog(files?: Array<File>) {
        this.dialogRef.close(files);
    }

    ngOnInit() {
        this.files = [];
    }
}
