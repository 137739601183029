import { Injectable } from '@angular/core';

import { LoginService } from './login.service';

import { Chat } from '../structures/chat';
import { Message } from '../structures/message';

/**
 * Service gérant les permissions des utilisateurs à l'intérieur d'easi-chat
 */
@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(private loginService: LoginService) {}

    canCreateChat() {
        return !this.loginService.isLearner();
    }

    canEditChat(chat: Chat) {
        if (this.loginService.getUser()) {
            return (
                chat.ownerid === this.loginService.getUser().id ||
                this.loginService.isChatModerator(chat) ||
                this.loginService.isNationalAdmin() ||
                (this.loginService.isLocalAdmin() &&
                    chat.owner.structureid === this.loginService.getUser().structureid)
            );
        }
        return false;
    }

    canQuitChat(chat: Chat) {
        return !this.loginService.isChatModerator(chat) || chat.canModeratorQuit;
    }

    canDeleteChat(chat: Chat) {
        if (this.loginService.getUser()) {
            return (
                (this.loginService.isChatModerator(chat) && !chat.canModeratorQuit) ||
                chat.ownerid === this.loginService.getUser().id ||
                this.loginService.isNationalAdmin() ||
                (this.loginService.isLocalAdmin() &&
                    chat.owner.structureid === this.loginService.getUser().structureid)
            );
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un post
     * @returns { boolean } Vrai si l'utilisateur peut créer un post, faux dans le cas contraire
     */
    canPostMessage(chat: Chat): boolean {
        if (this.loginService.getUser()) {
            for (const i in chat.users) {
                if (
                    chat.users[i].id === this.loginService.getUser().id &&
                    chat.users[i].role === 'reader'
                ) {
                    return false;
                }
            }
            for (const i in chat.groups) {
                if (chat.groups[i].id) {
                    for (const j in this.loginService.getUser().groups) {
                        if (
                            chat.groups[i].id === this.loginService.getUser().groups[j].id &&
                            chat.groups[i].role === 'reader'
                        ) {
                            return false;
                        }
                    }
                }
            }
            for (const i in chat.roles) {
                if (
                    this.loginService.getUser().roles[chat.roles[i].shortname] &&
                    chat.roles[i].role === 'reader' &&
                    chat.roles[i].structureid === this.loginService.getUser().structureid
                ) {
                    return false;
                }
            }
            return true;
        }
        return true;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'éditer un post
     * @returns { boolean } Vrai si l'utilisateur peut éditer un post, faux dans le cas contraire
     */
    canEditMessage(chat: Chat, message: Message): boolean {
        if (this.loginService.getUser()) {
            if (message.author.id === this.loginService.getUser().id) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de supprimer un post
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un post, faux dans le cas contraire
     */
    canDeleteMessage(chat: Chat, message: Message): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (chat.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (message.author.id === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isChatModerator(chat)) {
                return true;
            }
            return false;
        }
        return false;
    }
}
