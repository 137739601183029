import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { Chat } from '../structures/chat';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux chat
 */
@Injectable({
    providedIn: 'root'
})
export class ChatService {
    refreshListChats: Subject<boolean> = new Subject();
    refresh: Subject<string> = new Subject();
    setAsActive: Subject<Chat> = new Subject();

    chatsSubject: BehaviorSubject<Array<Chat>> = new BehaviorSubject([]);

    constructor(private http: HttpClient) {}

    /**
     * @param {number} id L'identifiant du chat à récuperer
     * Récupère les informations d'un chat spécifique
     * @returns {Observable} Un observable du chat récupéré
     */
    @log() getChat(id: number): Observable<any> {
        return this.http.get(`/discussions/${id}`);
    }

    /**
     * Récupère la liste des chats accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des chats de l'utilisateur courant
     */
    @log() getChats(): Observable<any> {
        return this.http.get('/discussions');
    }

    /**
     * retourne la liste des chats de l'utilisateur connecté sous forme d'observable
     * @returns {Observable} Observable d'un array de chats
     */
    getChatsSubject(): Observable<any> {
        return this.chatsSubject.asObservable();
    }

    @log() getSharedChats(params: any): Observable<any> {
        return this.http.get('/discussions/shared', { params });
    }

    @log() createChat(body: any): Observable<any> {
        body.roles = [];
        return this.http.post('/discussions', body);
    }

    @log() updateChat(id: number, body: any): Observable<any> {
        body.roles = [];
        return this.http.put('/discussions/' + id, body);
    }

    /**
     * @param {number} id L'identifiant du chat à supprimer
     * Supprime un chat
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression s'est déroulé correctement
     */
    @log() deleteChat(id: number): Observable<any> {
        return this.http.delete('/discussions/' + id);
    }

    /**
     * @param {number} id L'identifiant du chat à désactiver
     * Supprime un chat
     * @returns {Observable} Un observable d'un booléen indiquant si la désactivation s'est déroulé correctement
     */
    @log() disableChat(id: number): Observable<any> {
        return this.http.post('/discussions/' + id + '/remove_me', { disable: true });
    }

    @log() toggleMuteChat(chat: Chat): Observable<any> {
        return this.http.get(`/discussions/${chat.id}/${chat.isMuted ? 'unmute' : 'mute'}`);
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des chats
     */
    refreshChats(selectLast?: boolean) {
        this.refreshListChats.next(selectLast);
    }

    refreshChat() {
        this.refresh.next('');
    }

    emitSetAsActive(chat) {
        this.setAsActive.next(chat);
    }
}
