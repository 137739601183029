import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Message } from '../structures/message';
import { EasiHttpParams } from '../interceptors/easi-http-params';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    refresh: Subject<any> = new Subject();
    messageSent: Subject<any> = new Subject();
    startEditMessage: Subject<any> = new Subject();
    scrollToBottom: Subject<void> = new Subject();

    constructor(private http: HttpClient) {}

    @log() getMessages(
        nbMessages: number,
        discussionId: number,
        search?: string,
        offset?: number
    ): Observable<any> {
        const params: any = {
            limit: nbMessages,
            search: search
        };
        if (offset) {
            params.messageid = offset;
        }

        return this.http.get('/messages/' + discussionId, { params });
    }

    /**
     * @param {any} body Un objet contenant 2 paramètres : L'id du sujet dans lequel on souhaite créer le post, et le contenu du post
     * Crée un post
     * @returns {Observable} Un observable d'un booléen indiquant si la création du post s'est déroulé correctement
     */
    @log() createMessage(message: string, discussionid: number, quoteid?: number): Observable<any> {
        return this.http.post('/messages', { message, discussionid, quoteid });
    }

    /**
     * @param {any} body Un objet contenant 2 paramètres : L'id du sujet dans lequel on souhaite créer le post, et le contenu du post
     * Crée un post
     * @returns {Observable} Un observable d'un booléen indiquant si la création du post s'est déroulé correctement
     */
    @log() editMessage(message: Message): Observable<any> {
        return this.http.put(`/messages/${message.id}`, { message: message.message });
    }

    /**
     * @param { Number } messageId L'id du post que l'on souhaite supprimer
     * Supprime un post
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression du post s'est déroulé correctement
     */
    @log() deleteMessage(messageId: number): Observable<any> {
        return this.http.delete('/messages/' + messageId);
    }

    @log() createAttachment(messageId: number, file: File): Observable<any> {
        const body = new FormData();
        body.append('attachment', file);

        return this.http.post('/messages/' + messageId + '/attachments', body);
    }

    @log() downloadAttachment(messageId: number, attachmentName: string): Observable<any> {
        const config: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'blob';
            withCredentials?: boolean;
        } = {
            responseType: 'blob'
        };

        return this.http.get(
            '/messages/' + messageId + '/attachments/' + encodeURIComponent(attachmentName),
            {
                params: new EasiHttpParams({
                    log: `MessageService : downloadAttachment ${messageId}`,
                    params: config
                })
            }
        );
    }

    @log() deleteAttachment(messageId: number, attachmentName: string): Observable<any> {
        return this.http.delete(
            '/posts/' + messageId + '/attachments/' + encodeURIComponent(attachmentName)
        );
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des posts
     */
    refreshPosts() {
        this.refresh.next('');
    }

    updateMessage(event) {
        this.messageSent.next(event);
    }

    emitEditMessage(event) {
        this.startEditMessage.next(event);
    }

    emitScrollToBottom() {
        this.scrollToBottom.next();
    }
}
