import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';

import { ErrorComponent } from '../dialogs/error/error.component';
import { CreateChatComponent } from '../dialogs/create-chat/create-chat.component';
import { SharedChatComponent } from '../dialogs/shared-chat/shared-chat.component';
import { DeleteChatComponent } from '../dialogs/delete-chat/delete-chat.component';
import { DeleteMessageComponent } from '../dialogs/delete-message/delete-message.component';
import { UploadFilesComponent } from '../dialogs/upload-files/upload-files.component';
import { PreviewImageComponent } from '../dialogs/preview-image/preview-image.component';

import { Chat } from '../structures/chat';
import { Message } from '../structures/message';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog, private loginService: LoginService) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                this.closeAllDialogs();
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openErrorDialog(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(ErrorComponent, dialogConfig);
    }

    /**
     * @param {number} chatId L'identifiant du chat que l'utilisateur souhaite suprimer
     * Ouvre la fenetre d'erreur
     */
    openSharedChatDialog(type: string, id: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '800px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (type) {
            dialogConfig.data.type = type;
        }
        if (id) {
            dialogConfig.data.id = id;
        }

        const ref: MatDialogRef<SharedChatComponent> = this.dialog.open(
            SharedChatComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
        return ref.afterClosed();
    }

    openCreateChatDialog(chatId?: number, groupId?: number, userId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '650px';
        dialogConfig.maxHeight = '80vh';
        dialogConfig.data = {};
        if (chatId) {
            dialogConfig.data.chatId = chatId;
        }
        if (userId) {
            dialogConfig.data.userId = userId;
        }
        if (groupId) {
            dialogConfig.data.groupId = groupId;
        }
        this.dialog.open(CreateChatComponent, dialogConfig);
    }

    openDeleteChatDialog(chat: Chat) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...chat
        };

        const ref: MatDialogRef<DeleteChatComponent> = this.dialog.open(
            DeleteChatComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    openDeleteMessageDialog(message: Message) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...message
        };

        const ref: MatDialogRef<DeleteMessageComponent> = this.dialog.open(
            DeleteMessageComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    openUploadFilesDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<UploadFilesComponent> = this.dialog.open(
            UploadFilesComponent,
            dialogConfig
        );
        return new Observable((observer) => {
            ref.afterClosed().subscribe((result: Array<File>) => {
                observer.next(result);
                observer.complete();
            });
        });
    }

    openPreviewImage(url: string) {
        this.dialog.open(PreviewImageComponent, {
            id: 'previewDialog',
            hasBackdrop: true,
            data: { url: url }
        });
    }

    /**
     * ferme toutes les modales ouvertes (utilisé par is_still_connected)
     */
    closeAllDialogs(): void {
        this.dialog.closeAll();
    }
}
