import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from '../../services/users.service';
import { LoginService } from '../../services/login.service';
import { ChatService } from '../../services/chat.service';
import { MessageService } from '../../services/message.service';

import { Role } from '../../structures/role';
import { User } from '../../structures/user';

import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

@Component({
    selector: 'app-create-chat',
    templateUrl: './create-chat.component.html',
    styleUrls: ['./create-chat.component.scss']
})
export class CreateChatComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<CreateChatComponent>,
        private loginService: LoginService,
        private usersService: UsersService,
        private chatService: ChatService,
        private messageService: MessageService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    openErrorDialog: Subject<string> = new Subject();

    updateStructure$: Subscription;
    getStructures$: Subscription;
    getChat$: Subscription;
    getLearners$: Subscription;
    getLearnersCount$: Subscription;
    getTeachers$: Subscription;
    getTeachersCount$: Subscription;
    getLearnerGroups$: Subscription;
    getTeacherGroups$: Subscription;
    getUsers$: Subscription;
    getLearnerGroupsCount$: Subscription;
    getTeacherGroupsCount$: Subscription;
    getUsersCount$: Subscription;
    action$: Subscription;

    LIMIT = 30;
    RIGHT_READ_ONLY = 1;
    RIGHT_READ_WRITE = 7;
    RIGHT_MODERATOR = 15;

    structures: Array<any>;
    selectedStructure: any;

    searchTerm: string;
    searchTermValidated: string;

    chat: any;
    message: string;
    creationDone: boolean;

    currentTab: string;

    editMode: boolean;

    learnerGroups: Array<any>;
    currentLearnerGroupNumber: number;
    totalLearnerGroupNumber: number;
    currentPageLearnerGroup: number;

    teacherGroups: Array<any>;
    currentTeacherGroupNumber: number;
    totalTeacherGroupNumber: number;
    currentPageTeacherGroup: number;

    learners: Array<any>;
    currentLearnerNumber: number;
    totalLearnerNumber: number;
    currentPageLearner: number;

    teachers: Array<any>;
    currentTeacherNumber: number;
    totalTeacherNumber: number;
    currentPageTeacher: number;

    roles: Array<Role>;

    headerLabel: string;
    footerLabel: string;

    showDescription: boolean;
    isSending = false;

    ngOnInit() {
        this.chat = {};
        this.creationDone = false;
        this.searchTerm = '';

        this.currentTeacherNumber = 0;
        this.currentLearnerNumber = 0;
        this.currentLearnerGroupNumber = 0;
        this.currentTeacherGroupNumber = 0;

        this.currentPageLearnerGroup = -1;
        this.currentPageTeacherGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        this.showDescription = false;
        this.headerLabel = this.data.chatId
            ? 'Modifier les membres du chat'
            : 'Nouvelle discussion';
        this.footerLabel = this.data.chatId
            ? 'Enregistrer les modifications'
            : 'Rédiger un message';

        this.updateStructure$ = this.loginService.updateStructure.subscribe((data) => {
            this.selectedStructure = data;
        });

        this.getStructures$ = this.usersService.getStructures().subscribe(
            (data) => {
                this.structures = data.data.structures.map((structure: any) => {
                    return {
                        key: structure.id,
                        title: structure.name
                    };
                });
                this.structures.unshift({ key: 0, title: 'Administration nationale' });
                this.learnerGroups = [];
                this.teacherGroups = [];
                this.learners = [];
                this.teachers = [];

                if (this.loginService.isNationalAdmin()) {
                    this.selectedStructure = undefined;
                } else {
                    const structure = {
                        name: this.loginService.getUser().localStructure,
                        id: this.loginService.getUser().structureid
                    };
                    this.selectedStructure = structure;
                }
                if (this.data.chatId) {
                    this.editMode = true;
                    this.getChat$ = this.chatService.getChat(this.data.chatId).subscribe(
                        (chat: any) => {
                            this.chat = chat;
                            this.chat.name = this.chat.name !== 'empty' ? this.chat.name : '';
                            this.initNumberSelected();
                            this.initUsersSelected();
                            this.currentTab = 'learner';
                            this.refreshData(true);
                        },
                        (error: HttpErrorResponse) => {
                            this.openErrorDialog.next(error.error.userMessage);
                        }
                    );
                } else {
                    this.editMode = false;

                    this.chat.users = [];
                    this.chat.groups = [];
                    this.chat.description = '';
                    this.chat.name = '';
                    this.currentTab = 'learner';

                    if (this.data.userId) {
                        this.getUser(this.data.userId).subscribe((user) => {
                            this.chat.users.push({
                                id: user.id,
                                firstname: user.firstname,
                                lastname: user.lastname.toUpperCase(),
                                role: 'attendee',
                                rights: 1,
                                roles: user.roles
                            });
                            this.refreshData(true);
                            this.initNumberSelected();
                            this.initUsersSelected();
                        });
                    } else if (this.data.groupId) {
                        this.getGroup(this.data.groupId).subscribe((group) => {
                            this.chat.groups.push({
                                id: group.id,
                                name: group.name,
                                role: 'attendee',
                                rights: 1
                            });
                            this.refreshData(true);
                            this.initNumberSelected();
                            this.initUsersSelected();
                        });
                    } else {
                        this.refreshData(true);
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }

    getUser(userId: any) {
        const user$: Subject<User> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getUser: true, userId, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                user$.next(event.data.user);
            }
        });
        return user$.asObservable();
    }

    getGroup(groupId: any) {
        const group$: Subject<any> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getGroup: true, params: { groupid: groupId }, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                group$.next(event.data.group);
            }
        });
        return group$.asObservable();
    }

    ngOnDestroy() {
        if (this.updateStructure$) {
            this.updateStructure$.unsubscribe();
        }
        if (this.getStructures$) {
            this.getStructures$.unsubscribe();
        }
        if (this.getChat$) {
            this.getChat$.unsubscribe();
        }
        if (this.getLearnerGroups$) {
            this.getLearnerGroups$.unsubscribe();
        }
        if (this.getTeacherGroups$) {
            this.getTeacherGroups$.unsubscribe();
        }
        if (this.getUsers$) {
            this.getUsers$.unsubscribe();
        }
        if (this.getLearnerGroupsCount$) {
            this.getLearnerGroupsCount$.unsubscribe();
        }
        if (this.getTeacherGroupsCount$) {
            this.getTeacherGroupsCount$.unsubscribe();
        }
        if (this.getUsersCount$) {
            this.getUsersCount$.unsubscribe();
        }
        if (this.action$) {
            this.action$.unsubscribe();
        }
    }

    /**
     * Si en mode édition, initialise le nombre d'utilisateur/groupe membre du chat
     */
    initNumberSelected() {
        for (const i in this.chat.users) {
            if (
                (this.chat.users[i].roles.learner || this.chat.users[i].roles.prospect) &&
                this.chat.users[i].id !== this.chat.ownerid
            ) {
                this.currentLearnerNumber++;
            } else if (this.chat.users[i].id !== this.chat.ownerid) {
                this.currentTeacherNumber++;
            }
        }
        for (const i in this.chat.groups) {
            if (this.chat.groups[i].type === 'teacher') {
                this.currentTeacherGroupNumber++;
            } else {
                this.currentLearnerGroupNumber++;
            }
        }
    }

    initUsersSelected() {
        for (const i in this.chat.groups) {
            if (this.chat.groups[i]) {
                this.chat.groups[i].selected = true;
                if (
                    this.chat.groups[i].role === 'moderator' &&
                    this.chat.groups[i].rights === this.RIGHT_MODERATOR
                ) {
                    this.chat.groups[i].role = 'moderator';
                } else if (
                    (this.chat.groups[i].role === '' || this.chat.groups[i].role === 'attendee') &&
                    this.chat.groups[i].rights === this.RIGHT_READ_WRITE
                ) {
                    this.chat.groups[i].role = 'writer';
                } else if (
                    (this.chat.groups[i].role === '' || this.chat.groups[i].role === 'attendee') &&
                    this.chat.groups[i].rights === this.RIGHT_READ_ONLY
                ) {
                    this.chat.groups[i].role = 'reader';
                }
                if (this.chat.groups[i].type === 'teacher') {
                    this.teacherGroups.unshift(this.chat.groups[i]);
                } else {
                    this.learnerGroups.unshift(this.chat.groups[i]);
                }
            }
        }
        for (const i in this.chat.users) {
            if (this.chat.users[i].roles.learner || this.chat.users[i].roles.prospect) {
                this.chat.users[i].selected = true;
                if (
                    this.chat.users[i].role === 'moderator' &&
                    this.chat.users[i].rights === this.RIGHT_MODERATOR
                ) {
                    this.chat.users[i].role = 'moderator';
                } else if (
                    (this.chat.users[i].role === '' || this.chat.users[i].role === 'attendee') &&
                    this.chat.users[i].rights === this.RIGHT_READ_WRITE
                ) {
                    this.chat.users[i].role = 'writer';
                } else if (
                    (this.chat.users[i].role === '' || this.chat.users[i].role === 'attendee') &&
                    this.chat.users[i].rights === this.RIGHT_READ_ONLY
                ) {
                    this.chat.users[i].role = 'reader';
                }
                this.learners.unshift(this.chat.users[i]);
            } else if (this.chat.users[i].id !== this.chat.ownerid) {
                this.chat.users[i].selected = true;
                if (
                    this.chat.users[i].role === 'moderator' &&
                    this.chat.users[i].rights === this.RIGHT_MODERATOR
                ) {
                    this.chat.users[i].role = 'moderator';
                } else if (
                    (this.chat.users[i].role === '' || this.chat.users[i].role === 'attendee') &&
                    this.chat.users[i].rights === this.RIGHT_READ_WRITE
                ) {
                    this.chat.users[i].role = 'writer';
                } else if (
                    (this.chat.users[i].role === '' || this.chat.users[i].role === 'attendee') &&
                    this.chat.users[i].rights === this.RIGHT_READ_ONLY
                ) {
                    this.chat.users[i].role = 'reader';
                }
                this.teachers.unshift(this.chat.users[i]);
            }
        }
        for (const i in this.chat.roles) {
            if (this.chat.roles[i]) {
                this.chat.roles[i].selected = true;
                if (
                    this.chat.roles[i].role === 'moderator' &&
                    this.chat.roles[i].rights === this.RIGHT_MODERATOR
                ) {
                    this.chat.roles[i].role = 'moderator';
                } else if (
                    (this.chat.roles[i].role === '' || this.chat.roles[i].role === 'attendee') &&
                    this.chat.roles[i].rights === this.RIGHT_READ_WRITE
                ) {
                    this.chat.roles[i].role = 'writer';
                } else if (
                    (this.chat.roles[i].role === '' || this.chat.roles[i].role === 'attendee') &&
                    this.chat.roles[i].rights === this.RIGHT_READ_ONLY
                ) {
                    this.chat.roles[i].role = 'reader';
                }
                let existingRoles = false;
                for (const j in this.roles) {
                    if (this.chat.roles[i].id === this.roles[j].id) {
                        existingRoles = true;
                        this.roles[j].structureid.push(this.chat.roles[i].structureid);
                    }
                }
                if (!existingRoles) {
                    this.chat.roles[i].structureid = [this.chat.roles[i].structureid];
                    this.roles.unshift(this.chat.roles[i]);
                }
            }
        }
    }

    /**
     * Affiche la page des groupes suivante
     */
    nextPageLearnerGroup(newSearch: boolean) {
        this.currentPageLearnerGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }

        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageLearnerGroup * this.LIMIT;
        params.limit = this.LIMIT;
        params.type = 'learner';

        this.getLearnerGroups$ = this.usersService.getGroups(params).subscribe(
            (data) => {
                if (newSearch) {
                    const learnerGroupsTmp = [];
                    for (const i in this.learnerGroups) {
                        if (this.learnerGroups[i].selected === true) {
                            learnerGroupsTmp.push(this.learnerGroups[i]);
                        }
                    }
                    this.learnerGroups = learnerGroupsTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.learnerGroups) {
                            if (data[i].id === this.learnerGroups[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.learnerGroups = this.learnerGroups.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }
    nextPageTeacherGroup(newSearch: boolean) {
        this.currentPageTeacherGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageTeacherGroup * this.LIMIT;
        params.limit = this.LIMIT;
        params.type = 'teacher';

        this.getTeacherGroups$ = this.usersService.getGroups(params).subscribe(
            (data) => {
                if (newSearch) {
                    const teacherGroupsTmp = [];
                    for (const i in this.teacherGroups) {
                        if (this.teacherGroups[i].selected === true) {
                            teacherGroupsTmp.push(this.teacherGroups[i]);
                        }
                    }
                    this.teacherGroups = teacherGroupsTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.teacherGroups) {
                            if (data[i].id === this.teacherGroups[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.teacherGroups = this.teacherGroups.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }

    /**
     * Affiche la page des apprenants suivante
     */
    nextPageLearner(newSearch: boolean) {
        this.currentPageLearner++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.role = 'learner|prospect';
        params.offset = this.currentPageLearner * this.LIMIT;
        params.limit = this.LIMIT;
        this.getLearners$ = this.usersService.getUsers(params).subscribe(
            (data) => {
                if (this.data.chatId) {
                    for (const i in data) {
                        if (data[i].id === this.chat.ownerid) {
                            data.splice(i, 1);
                        }
                    }
                }
                for (const i in data) {
                    if (data[i].id === this.loginService.getUser().id) {
                        data.splice(i, 1);
                    }
                }

                if (newSearch) {
                    const learnersTmp = [];
                    for (const i in this.learners) {
                        if (this.learners[i].selected === true) {
                            learnersTmp.push(this.learners[i]);
                        }
                    }
                    this.learners = learnersTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.learners) {
                            if (data[i].id === this.learners[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.learners = this.learners.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }

    /**
     * Affiche la page des formateurs suivante
     */
    nextPageTeacher(newSearch: boolean) {
        this.currentPageTeacher++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageTeacher * this.LIMIT;
        params.limit = this.LIMIT;
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';

        this.getTeachers$ = this.usersService.getUsers(params).subscribe(
            (data) => {
                if (this.data.chatId) {
                    for (const i in data) {
                        if (data[i].id === this.chat.ownerid) {
                            data.splice(i, 1);
                        }
                    }
                }
                for (const i in data) {
                    if (data[i].id === this.loginService.getUser().id) {
                        data.splice(i, 1);
                    }
                }

                if (newSearch) {
                    const teachersTmp = [];
                    for (const i in this.teachers) {
                        if (this.teachers[i].selected === true) {
                            teachersTmp.push(this.teachers[i]);
                        }
                    }
                    this.teachers = teachersTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.teachers) {
                            if (data[i].id === this.teachers[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.teachers = this.teachers.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }

    /**
     * Récupère le nombre de groupes dans la structure actuelle
     */
    getLearnerGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'learner';

        this.getLearnerGroupsCount$ = this.usersService
            .getGroupsCount(params)
            .subscribe((data: any) => {
                this.totalLearnerGroupNumber = data.count;
            });
    }
    /**
     * Récupère le nombre de groupes dans la structure actuelle
     */
    getTeacherGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'teacher';

        this.getTeacherGroupsCount$ = this.usersService
            .getGroupsCount(params)
            .subscribe((data: any) => {
                this.totalTeacherGroupNumber = data.count;
            });
    }

    /**
     * Récupère le nombre d'apprenants dans la structure actuelle
     */
    getLearnerNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role = 'learner|prospect';
        params.search = this.searchTerm ? this.searchTerm : '';

        this.getUsersCount$ = this.usersService.getUsersCount(params).subscribe((data: any) => {
            this.totalLearnerNumber = data.count;
        });
    }

    /**
     * Récupère le nombre de formateurs dans la structure actuelle
     */
    getTeacherNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';
        params.search = this.searchTerm ? this.searchTerm : '';

        this.getUsersCount$ = this.usersService.getUsersCount(params).subscribe((data: any) => {
            this.totalTeacherNumber = data.count;
        });
    }

    getCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups) {
            return this.teacherGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups) {
            return this.learnerGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentLearnerNumber(): number {
        if (this.learners) {
            return this.learners.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentTeacherNumber(): number {
        if (this.teachers) {
            return this.teachers.filter((l) => l.selected).length;
        }
        return 0;
    }

    /**
     * retourne le nombre total de groupes sélectionnés, y compris ceux qui ne sont pas visibles dans le cas d'une recherche
     */
    getTotalCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups !== undefined) {
            return this.learnerGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }

    /**
     * retourne le nombre total de groupes sélectionnés, y compris ceux qui ne sont pas visibles dans le cas d'une recherche
     */
    getTotalCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups !== undefined) {
            return this.teacherGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentLearnerNumber(): number {
        if (this.learners !== undefined) {
            return this.learners.filter((l) => l.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentTeacherNumber(): number {
        if (this.teachers !== undefined) {
            return this.teachers.filter((t) => t.selected).length;
        } else {
            return 0;
        }
    }

    updateStructure($event: any) {
        setTimeout(() => {
            this.structures = $event;
            if (this.structures.some((s) => s.selected)) {
                for (const i in this.structures) {
                    if (this.structures[i].selected) {
                        this.selectedStructure = {
                            id: this.structures[i].key,
                            name: this.structures[i].title
                        };
                    }
                }
            } else if (this.isNationalAdmin()) {
                this.selectedStructure = undefined;
            }
            this.refreshData(true);
        });
    }

    /**
     * Rafraichit la liste des utilisateurs et des groupes
     */
    refreshData(newSearch: boolean) {
        this.currentPageLearnerGroup = -1;
        this.currentPageTeacherGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        if (
            this.selectedStructure &&
            this.selectedStructure.id !== undefined &&
            this.selectedStructure.id !== 0
        ) {
            this.loginService.updateLastStructure(this.selectedStructure);
        }
        if (this.showGroupTab()) {
            this.nextPageLearnerGroup(newSearch);
            this.getLearnerGroupNumber();
            this.nextPageTeacherGroup(newSearch);
            this.getTeacherGroupNumber();
        }
        this.nextPageLearner(newSearch);
        this.getLearnerNumber();
        this.nextPageTeacher(newSearch);
        this.getTeacherNumber();

        // Activation du pipe/filtrage direct à l'affichage pour les learners sélectionnés
        this.searchTermValidated = this.searchTerm;
    }

    /**
     * Affiche le champ permettant de renseigner une description
     */
    toggleDescription() {
        this.showDescription = true;
    }

    /**
     * Indique si le menu déroulant des structures doit s'afficher pour l'utilisateur actuel
     * @returns {boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    isNationalAdmin(): boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     * Ferme la fenêtre de création de chat
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de chat
     * Modifie l'onglet courant de la fenêtre
     */
    switchCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    /**
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de chat
     * Indique si l'onglet passé en paramètre est l'onglet courant
     * @returns {Boolean} True si l'onglet passé en paramètre est l'onglet courant, false dans le cas contraire
     */
    isCurrentTab(tab: string): boolean {
        return this.currentTab && this.currentTab.localeCompare(tab) === 0;
    }

    /**
     * Indique si l'utilisateur courant a le droit de voir l'onglet des groupes
     */
    showGroupTab() {
        return !(
            this.loginService.getUser().roles.corporationTeacher ||
            this.loginService.getUser().roles.siteTeacher
        );
    }

    showTeacherTab() {
        return !this.loginService.getUser().roles.tutor;
    }

    /**
     * Crée/Met à jour le chatP
     */
    createChat() {
        if (this.canCreateChat() && !this.isSending) {
            this.isSending = true;
            this.creationDone = true;
            this.chat.groups = [];
            this.chat.users = [];
            this.formatMembers();
            const action = this.data.chatId
                ? this.chatService.updateChat(this.data.chatId, this.chat)
                : this.chatService.createChat(this.chat);
            this.action$ = action.subscribe(
                (data: any) => {
                    if (!this.editMode) {
                        this.messageService.createMessage(this.message, data).subscribe(() => {
                            this.chatService.refreshChats(true);
                            this.chat.id = data;
                            this.chatService.emitSetAsActive(this.chat);
                            this.router.navigate(['/chat/' + data]);
                            this.messageService.updateMessage(data);
                        });
                        this.isSending = false;
                        this.closeDialog();
                    } else {
                        this.chatService.refreshChats();
                        this.isSending = false;
                        this.closeDialog();
                    }
                },
                (error: HttpErrorResponse) => {
                    this.isSending = false;
                    this.openErrorDialog.next(error.error.userMessage);
                }
            );
        }
    }

    /**
     * Indique si toutes les informations nécéssaires à la création du chat sont présentes
     * @returns {Boolean} True si le chat peut être crée, false dans le cas contraire
     */
    canCreateChat(): boolean {
        // Uncomment here is all moderators should be able to modify a chat. Actually blocked by the backend.
        if (this.loginService.isChatModerator(this.chat)) {
            return true;
        }
        if (
            this.loginService.getUser().roles.learner ||
            this.loginService.getUser().roles.prospect
        ) {
            for (const i in this.teachers) {
                if (this.teachers[i].selected === true) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    }

    formatMembers(): void {
        for (const i in this.learnerGroups) {
            if (this.learnerGroups[i].selected) {
                this.chat.groups.push(this.learnerGroups[i]);
            }
        }

        for (const i in this.teacherGroups) {
            if (this.teacherGroups[i].selected) {
                this.chat.groups.push(this.teacherGroups[i]);
            }
        }

        for (const i in this.learners) {
            if (this.learners[i].selected) {
                this.chat.users.push(this.learners[i]);
            }
        }

        for (const i in this.teachers) {
            if (this.teachers[i].selected) {
                this.chat.users.push(this.teachers[i]);
            }
        }
    }
}
