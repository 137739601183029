<div
    [attr.data-message-id]="message.id"
    class="message-container {{ position }} "
    [ngClass]="{ temporary: message.temporary, quote: quote, cancelable: cancelable }"
    *ngIf="!message.deleted"
>
    <div class="message-owner">
        <span>{{ isCurrentOwner() ? '' : author + ', ' }}{{ timestamp }}</span>
        <span *ngIf="quote && cancelable" class="icon-close" (click)="cancelQuote()"></span>
    </div>
    <div class="message" [class.strong]="!isRead()">
        <div id="icon-container" *ngIf="!isCurrentOwner()">
            <span class="icon icon-Messages-cercle"></span>
            <div id="initials">
                <span>{{ initials }}</span>
            </div>
        </div>
        <div class="message-content" [ngClass]="{ editing: message.isEditing }">
            <span *ngFor="let text of content; let i = index">
                <span *ngIf="message.isEditing" class="editing-label"
                    >En cours de modification... (Echap pour annuler)</span
                >
                <span *ngIf="message.quote && !quote">
                    <app-chat-entry
                        [message]="message.quote"
                        [last]="false"
                        [chat]="chat"
                        [quote]="true"
                        [cancelable]="false"
                        (click)="goToQuotedMessage()"
                    >
                    </app-chat-entry>
                </span>
                <span [innerHTML]="text"></span>
                <a target="blank" href="{{ links[i] }}" *ngIf="links[i]">{{ links[i] }}</a>
            </span>
            <div class="action-icons-container">
                <span
                    class="icon-bin"
                    *ngIf="canDeleteMessage()"
                    (click)="openDeleteMessageDialog()"
                    [tooltip]="'Supprimer'"
                    tooltipPosition="left"
                    tooltipColor="#424242"
                ></span>
                <span
                    class="icon-Editer"
                    *ngIf="canEditMessage()"
                    (click)="editMessage()"
                    [tooltip]="'Editer'"
                    tooltipPosition="below"
                    tooltipColor="#424242"
                ></span>
                <span
                    class="icon-assigner"
                    *ngIf="canQuoteMessage()"
                    (click)="quoteMessage()"
                    [tooltip]="'Citer'"
                    tooltipPosition="below"
                    tooltipColor="#424242"
                ></span>
            </div>
            <div class="attachment-container" *ngFor="let attachment of message.attachments">
                <img
                    class="pic-preview"
                    alt="preview"
                    *ngIf="isImage(attachment)"
                    (click)="openPreview(attachment)"
                    [src]="getAttachmentUrl(attachment)"
                />
                <!-- <span class="icon-document"></span> -->
                <a class="name" [href]="downloadAttachment(attachment.name)">{{
                    attachment.name
                }}</a>
                <span class="size">({{ getFormattedSize(attachment.size) }})</span>
            </div>
        </div>
    </div>
</div>

<div class="message-container {{ position }} deleted" *ngIf="message.deleted">
    <div class="message-owner">
        <span>{{ isCurrentOwner() ? '' : author + ', ' }}{{ timestamp }}</span>
    </div>
    <div class="message">
        <div id="icon-container" *ngIf="!isCurrentOwner()">
            <span class="icon icon-Messages-cercle"></span>
            <div id="initials">
                <span>{{ initials }}</span>
            </div>
        </div>
        <div class="message-content deleted">
            <span>
                <span>Ce message a été supprimé.</span>
            </span>
        </div>
    </div>
</div>
