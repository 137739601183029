<div class="container" [ngClass]="{ selected: isSelected() }">
    <div class="header">
        <span class="icon-Messages-cercle" [ngClass]="{ unread: hasUnreadMessage() }"></span>
        <span class="members" [ngClass]="{ unread: hasUnreadMessage() }">{{ getChatName() }}</span>
        <span class="date" [ngClass]="{ unread: hasUnreadMessage() }">{{
            getLastMessageDate()
        }}</span>
        <div class="icons">
            <!-- <span [ngClass]="getChatNotificationsIcon()" [tooltip]="getNotificationsIconTooltip()" tooltipPosition="left" tooltipColor="#424242" (click)="toggleChatNotifications($event, chat)" ></span> -->
            <span
                class="icon-Editer"
                *ngIf="canEditChat()"
                (click)="openEditChatDialog()"
                [tooltip]="'Editer les participants'"
                tooltipPosition="left"
                tooltipColor="#424242"
            ></span>
            <span
                class="icon-unlog"
                *ngIf="canQuitChat()"
                (click)="quitChat()"
                [tooltip]="'Quitter la conversation'"
                tooltipPosition="left"
                tooltipColor="#424242"
            ></span>
            <span
                class="icon-bin"
                *ngIf="canDeleteChat()"
                (click)="openDeleteChatDialog()"
                [tooltip]="'Supprimer la conversation'"
                tooltipPosition="left"
                tooltipColor="#424242"
            ></span>
            <span
                class="icon-selected"
                (click)="markChatAsRead()"
                [tooltip]="'Marquer comme lu'"
                tooltipPosition="left"
                tooltipColor="#424242"
            ></span>
        </div>
    </div>
    <div
        class="message"
        [ngClass]="{ unread: hasUnreadMessage() }"
        [innerHTML]="getLastMessage()"
    ></div>
</div>
