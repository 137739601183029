<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Suppression du message</span>
</div>
<mat-dialog-content class="content" *ngIf="!deletionDone">
    Souhaitez-vous vraiment supprimer ce message ? <br />
    Merci de confirmer.
</mat-dialog-content>

<mat-dialog-content class="content" *ngIf="deletionDone">
    Le message a bien été supprimé.
</mat-dialog-content>

<div class="footer" *ngIf="!deletionDone">
    <div class="cancel-button" (click)="closeDialog()">Annuler</div>
    <div class="delete-button" (click)="deleteMessage(message)">
        Supprimer
        <span class="icon-bin"></span>
    </div>
</div>
<div class="footer" *ngIf="deletionDone">
    <div class="close-button" (click)="closeDialog()">OK</div>
</div>
