<div class="footer">
    <div class="quote-container">
        <div *ngIf="quote">
            <app-chat-entry
                [message]="quote"
                [last]="false"
                [chat]="chat"
                [quote]="true"
                [cancelable]="true"
                (clickQuoteMessage)="cancelQuote()"
            ></app-chat-entry>
        </div>
    </div>
    <div class="response-container">
        <div class="input-container">
            <div
                #messageInput
                class="message-input"
                id="message-input"
                *ngIf="canPostMessage()"
                contenteditable
                [ngClass]="{ placeholder: !message.message.length }"
                (input)="message.message = $event.target.textContent"
                (keydown.enter)="sendMessage($event)"
                (keyup.arrowup)="editLastMessage()"
                (keydown.esc)="cancelMessageEdition()"
                (paste)="pasteHTML($event)"
            ></div>
            <div class="message-input-disabled" *ngIf="!canPostMessage()">
                Vous ne pouvez pas poster de message dans cette discussion
            </div>
            <span
                class="icon-Envoyer"
                *ngIf="canPostMessage()"
                (click)="sendMessage($event)"
            ></span>
        </div>
        <div class="upload-container" (click)="openUploadFilesDialog()">
            <div class="icon-Importer"></div>
        </div>
        <div class="file-container" *ngIf="files.length > 0">
            <div class="attachment-container" *ngFor="let file of files; index as i">
                <span class="name">{{ file.name }}</span>
                <span class="size">({{ getFormattedSize(file.size) }})</span>
                <span class="icon-close" (click)="removeAttachment(i)"></span>
            </div>
        </div>
    </div>
</div>
