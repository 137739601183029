import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';

import { MessageService } from '../../services/message.service';

import { Message } from '../../structures/message';

/**
 * Composant gérant l'affichage de la fenêtre d'erreur
 */
@Component({
    selector: 'app-delete-message',
    templateUrl: './delete-message.component.html',
    styleUrls: ['./delete-message.component.scss']
})
export class DeleteMessageComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<DeleteMessageComponent>,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public message: Message
    ) {}

    openErrorDialog: Subject<string> = new Subject();

    deleteMessage$: Subscription;

    deletionDone: boolean;

    ngOnInit() {
        this.deletionDone = false;
    }

    ngOnDestroy() {
        if (this.deleteMessage$) {
            this.deleteMessage$.unsubscribe();
        }
    }

    deleteMessage(message) {
        this.deleteMessage$ = this.messageService.deleteMessage(message.id).subscribe(
            (data: any) => {
                this.deletionDone = true;
                this.closeDialog();
                this.messageService.refreshPosts();
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }
    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
