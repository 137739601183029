<div class="main" [ngClass]="{ hidden: reduced }">
    <div class="header">
        <div class="chats-count">
            <strong>{{ chatsCount }}</strong> chats
        </div>
        <div class="right-container">
            <div class="search-container">
                <div
                    class="search-input-container"
                    [ngClass]="{ active: activeSearch, empty: isSearchEmpty() }"
                >
                    <input
                        class="library-search-field"
                        placeholder="Chercher..."
                        [(ngModel)]="search"
                        (keyup.enter)="refreshDataFromSearch()"
                        #searchElement
                    />
                    <span class="icon-Fermerdetails" (mousedown)="resetSearch($event)"></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="refreshDataFromSearch()"
                        *ngIf="activeSearch"
                    ></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="toggleActiveSearch()"
                        *ngIf="!activeSearch"
                    ></span>
                </div>
            </div>
            <div
                class="icon-minimizebloc"
                [ngClass]="{ inversed: reduced }"
                (click)="toggleReduce()"
            ></div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="chat-container">
        <app-nav-entry
            *ngFor="let chat of chats | search: search"
            [chat]="chat"
            (click)="selectChat(chat)"
            [selected]="selectedChat?.id"
        ></app-nav-entry>
    </div>
    <div class="add-chat" (click)="openCreateChatDialog()" *ngIf="!isLearner() && userReady()">
        <span class="icon-plus"></span>
    </div>
</div>
