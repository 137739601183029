<div class="container">
    <div class="header">
        <div class="icon-Messages-cercle"></div>
        <div class="users-list">{{ getTitle() }}</div>
        <div class="search-field">
            <input
                class="input-container"
                type="text"
                placeholder="Chercher..."
                [(ngModel)]="search"
                (keyup.enter)="refreshMessages()"
            />
            <span class="icon-search" (click)="refreshMessages()"></span>
        </div>
    </div>
    <div
        class="main-container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolledUp)="getPreviousMessages()"
    >
        <app-chat-entry
            *ngFor="let msg of messages; last as isLast"
            [message]="msg"
            [last]="isLast"
            [chat]="chat"
            (clickEditMessage)="editMessage($event)"
            (clickQuoteMessage)="quoteMessage($event)"
            (scrollToQuoteMessage)="scrollToMessage($event)"
        >
        </app-chat-entry>
    </div>
    <app-chat-footer [chat]="chat" [messages]="messages" [quote]="quote"></app-chat-footer>
</div>
