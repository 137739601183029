import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
    transform(chats: any[], search: string): any {
        let array: any[];
        array = [];
        if (!search) {
            return chats;
        }
        for (const i in chats) {
            if (chats[i].name !== 'empty') {
                if (chats[i].name.toLowerCase().includes(search.toLowerCase())) {
                    array.push(chats[i]);
                }
            } else {
                if (chats[i].usersName.toString().toLowerCase().includes(search.toLowerCase())) {
                    array.push(chats[i]);
                }
            }
        }
        return array;
    }
}
