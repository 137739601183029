import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../services/dialog.service';
import { ChatService } from '../../../services/chat.service';
import { MessageService } from '../../../services/message.service';
import { PermissionService } from '../../../services/permission.service';

import { Chat } from 'src/app/structures/chat';
import { Subscription } from 'rxjs';
import { FlashMessageService } from 'src/app/services/flash-message.service';

@Component({
    selector: 'app-nav-entry',
    templateUrl: './nav-entry.component.html',
    styleUrls: ['./nav-entry.component.scss']
})
export class NavEntryComponent implements OnDestroy {
    @Input() chat: Chat;
    @Input() selected: any;

    disabledChat$: Subscription;
    getMessages$: Subscription;
    toggleNotifications$: Subscription;

    constructor(
        private dialogService: DialogService,
        private chatService: ChatService,
        private messageService: MessageService,
        private permissionService: PermissionService,
        private flashMessageService: FlashMessageService,
        private router: Router
    ) {}

    ngOnDestroy() {
        if (this.disabledChat$) {
            this.disabledChat$.unsubscribe();
        }
        if (this.getMessages$) {
            this.getMessages$.unsubscribe();
        }
    }

    getMembersList() {
        return this.chat.usersName.join(', ');
    }
    /**
     * retourne le nom (string) de la discussion le cas échéant, ou retourne la liste des membres (string)
     */
    getChatName() {
        return this.chat.name !== 'empty' ? this.chat.name : this.getMembersList();
    }

    getLastMessageDate() {
        if (this.chat.lastMessage) {
            const lastMessageDate = new Date(this.chat.lastMessage.timecreated);
            if (
                lastMessageDate.toLocaleDateString('fr-FR') ===
                new Date().toLocaleDateString('fr-FR')
            ) {
                return lastMessageDate.toLocaleTimeString('fr-FR');
            } else {
                return lastMessageDate.toLocaleDateString('fr-FR');
            }
        }
    }

    getLastMessage() {
        if (this.chat.lastMessage) {
            return this.chat.lastMessage.message;
        }
    }

    openEditChatDialog() {
        this.dialogService.openCreateChatDialog(this.chat.id);
    }

    openDeleteChatDialog() {
        this.dialogService.openDeleteChatDialog(this.chat);
    }

    canEditChat() {
        return this.permissionService.canEditChat(this.chat);
    }

    canDeleteChat() {
        return this.permissionService.canDeleteChat(this.chat);
    }

    canQuitChat() {
        return this.permissionService.canQuitChat(this.chat);
    }

    quitChat() {
        this.disabledChat$ = this.chatService.disableChat(this.chat.id).subscribe(
            () => {
                this.chatService.refreshChats();
                this.router.navigate(['/']);
                this.flashMessageService.flash('Vous avez quitté la conversation');
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
            }
        );
    }

    markChatAsRead() {
        this.getMessages$ = this.messageService
            .getMessages(1, this.chat.id, '', this.chat.lastMessage.id)
            .subscribe(() => {
                this.chatService.refreshChats();
            });
    }

    hasUnreadMessage() {
        if (this.chat.lastMessage) {
            return this.chat.lastMessage.readOn === null;
        } else {
            return false;
        }
    }

    isSelected() {
        if (this.selected) {
            return this.chat.id === this.selected;
        }
    }

    getChatNotificationsIcon(): string {
        if (this.chat.isMuted) {
            return 'icon-notifications';
        }
        return 'icon-notifications-barree';
    }

    getNotificationsIconTooltip(): string {
        if (this.chat.isMuted) {
            return 'activer les notifications';
        }
        return 'désactiver les notifications';
    }

    toggleChatNotifications($event: Event, chat: Chat): void {
        $event.stopImmediatePropagation();
        this.toggleNotifications$ = this.chatService.toggleMuteChat(chat).subscribe((data) => {
            this.chat.isMuted = !this.chat.isMuted;
            this.flashMessageService.flash(
                `les notifications de la discussion ont été ${
                    this.chat.isMuted ? 'désactivées' : 'activées'
                }`
            );
        });
    }
}
