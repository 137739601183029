import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ChatService } from './services/chat.service';
import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { DialogService } from './services/dialog.service';

import { isMobile } from 'mobile-device-detect';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();

    currentChat: number;
    mouseMoved: boolean;
    isPanelOpen = true;
    loading = true;

    constructor(
        private chatService: ChatService,
        private loginService: LoginService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private router: Router
    ) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data.structureid) {
            this.loginService.setLastStructure(event.data);
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrentUser().subscribe((data: any) => {
                    if (data === 0) {
                        window.location.href = this.configService.getRedirectEndPoint();
                    } else {
                        if (isMobile) {
                            if (this.loading) {
                                this.dialogService.openErrorDialog(
                                    "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                                );
                            }
                        }
                        this.loading = false;
                    }
                })
            );
        } else if (event.data.discussionid) {
            this.chatService.refreshChats();
            this.chatService.refreshChat();
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateCurrentChat(event: any) {
        this.router.navigate(['/chat/' + event.id]);
    }

    toggleNav() {
        this.isPanelOpen = !this.isPanelOpen;
    }

    isLoading() {
        return this.loading;
    }

    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUserChat().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }
}
