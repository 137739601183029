import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, fromEvent, Subject } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { CookieService } from 'ngx-cookie-service';
import { log } from './decorators/log.decorator';
import { EasiHttpParams } from '../interceptors/easi-http-params';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    private user: any;
    moderator: Array<number>;

    lastStructure: any = {};
    closeAllDialogs$: Subject<boolean> = new Subject();

    updateStructure: Subject<any> = new Subject();
    // event user prêt
    userReady: Subject<any> = new Subject();

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private cookieService: CookieService
    ) {}

    getUser(): any {
        if (this.user) {
            return this.user;
        }
    }

    /**
     * @returns {any} la derniere structure selectionnee par l'utilisateur.
     */
    getLastStructure(): any {
        return this.lastStructure;
    }

    /**
     * @description modifie l'id de la nom de la derniere structure qui avait ete selectionee par l'utilisateur.
     * @param {any} structure recue via un cookie.
     * @returns {void}
     */
    setLastStructure(structure: any): void {
        if (structure.structureid) {
            this.lastStructure.id = structure.structureid;
            this.lastStructure.name = structure.structurename;
        } else if (structure.id) {
            this.lastStructure.id = structure.id;
            this.lastStructure.name = structure.name;
        }
        this.updateStructure.next(this.lastStructure);
    }

    /**
     * @description update la structure et poste le message a easi-shared.
     * @returns {void}
     */
    updateLastStructure(structure: any): void {
        const iframe: any = document.getElementById('header-container');

        this.setLastStructure(structure);
        iframe.contentWindow.postMessage(
            { setCookies: { structureid: structure.id, structurename: structure.name } },
            '*'
        );
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isLearner(): boolean {
        if (this.user) {
            return this.user.roles.learner || this.user.roles.prospect;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur
     * @returns {boolean} True si l'utilisateur actuel est un administrateur, faux dans le cas contraire
     */
    isAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin || this.user.roles.localAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur national
     * @returns {boolean} True si l'utilisateur actuel est un administrateur national, faux dans le cas contraire
     */
    isNationalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur local
     * @returns {boolean} True si l'utilisateur actuel est un administrateur local, faux dans le cas contraire
     */
    isLocalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.localAdmin;
        }
    }

    isTeacher(): boolean {
        if (this.user) {
            const roles: any = this.user.roles;
            return (
                roles.corporationTeacher ||
                roles.externalTeacher ||
                roles.internalTeacher ||
                roles.siteTeacher ||
                roles.tutor
            );
        }
    }

    /**
     * Indique si l'utilisateur actuel est un modérateur du chat passé en paramètre
     * @returns {boolean} True si l'utilisateur actuel est un administrateur local, faux dans le cas contraire
     */
    isChatModerator(chat: any): boolean {
        if (this.moderator && this.moderator.indexOf(chat.id) > -1) {
            return true;
        } else if (this.getUser() && chat.ownerid === this.getUser().id) {
            return true;
        } else if (this.isAdmin()) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrentUser(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getCurrent', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.current !== undefined;
            }),
            tap({
                error: (error) => {
                    this.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @param {boolean} noError désactive la popup d'erreur
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrentUserChat(noError?: boolean): Observable<any> {
        return this.http
            .get('/user/current', { params: new EasiHttpParams({ noError: noError }) })
            .pipe(
                tap(
                    (data) => {
                        if (this.cookieService.check('redirect-url')) {
                            const redirectUrl = this.cookieService.get('redirect-url');
                            this.cookieService.delete('redirect-url', '/');
                            window.location.href = redirectUrl;
                            window.location.reload();
                        }
                        this.user = data;
                        this.getModerators().subscribe((moderatorArray: Array<number>) => {
                            this.moderator = moderatorArray;
                        });
                        this.userReady.next(this.user);
                    },
                    (error) => {
                        const expires = new Date();
                        expires.setFullYear(expires.getFullYear() + 1);
                        this.cookieService.set(
                            'redirect-url',
                            window.location.href,
                            expires,
                            '/',
                            '',
                            true,
                            'None'
                        );
                    }
                )
            );
    }

    /**
     * Renvoie la liste des chats dont l'utilisateur actuel est modérateur
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getModerators(): Observable<any> {
        return this.http.get('/discussions/moderator', {
            params: new EasiHttpParams({ noError: false })
        });
    }
    @log() postCookies() {
        return this.http.post('/setTestCookie', undefined);
    }
    @log() getCookies() {
        return this.http.get('/getTestCookie');
    }

    isAuthenticated(error: HttpErrorResponse) {
        if (error.error.errorCode && error.error.errorCode === 'USER_NOT_AUTH') {
            window.location.href = this.configService.getRedirectEndPoint();
        }
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            this.http.get<boolean>(`/is_still_connected.php`).subscribe((data: boolean) => {
                if (data === false) {
                    const iframe: HTMLIFrameElement = document.getElementById(
                        'header-container'
                    ) as HTMLIFrameElement;
                    iframe.contentWindow.postMessage('userDisconnected', '*');
                    this.closeAllDialogs$.next(true);
                }
            });
        }, 5 * 60 * 1000);
    }
}
