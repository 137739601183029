import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, fromEvent } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { LogService } from './log.service';
import { LoginService } from './login.service';
import { EasiHttpParams } from '../interceptors/easi-http-params';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux utilisateurs/groupes
 */
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
        private http: HttpClient,
        private logService: LogService,
        private loginService: LoginService
    ) {}

    /**
     * Récupère la liste des structures
     * @returns {Observable} Un observable de la liste des structures
     */
    @log() getStructures(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getStructures', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.structures !== undefined;
            }),
            tap({
                error: (error) => {
                    this.loginService.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du groupe que l'on souhaite récuperer
     * Récupère un groupe spécifique
     * @returns {Observable} Un observable du groupe récupéré
     */
    @log() getGroup(id: number): Observable<any> {
        return this.http.get('/groups/' + id);
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * Récupère une liste de groupe
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getGroups(params: any): Observable<any> {
        return this.http.get('/groups', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid
     * Récupère le nombre de groupes correspondants aux paramètres sélectionnés
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getGroupsCount(params: any): Observable<any> {
        return this.http.get('/groups?count=true', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : role et structureid
     * Récupère une liste de groupe
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getUsers(params: any): Observable<any> {
        return this.http.get('/users', { params });
    }

    /**
     * @param {any} params Un objet contenant 1 paramètre : role et structureid
     * Récupère le nombre d'utilisateurs correspondant aux paramètres
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getUsersCount(params: any): Observable<any> {
        return this.http.get('/users?count=true', { params });
    }

    /**
     * @param {number} id L'identifiant de l'utilisateur que l'on souhaite récuperer
     * Récupère un utilisateur spécifique
     * @returns {Observable} Un observable de l'utilisateur récupéré
     */
    @log() getUser(id: number): Observable<any> {
        return this.http.get('/users/' + id);
    }

    getUserRole(roles: any): string {
        for (const key in roles) {
            if (roles[key]) {
                return key;
            }
        }
    }

    isUserInGroup(user, group): boolean {
        for (const member of group) {
            if (user.id === member.id) {
                return true;
            }
        }
        return false;
    }

    isCurrentModerator(chat: any): boolean {
        const current = this.loginService.getUser();

        if (current.id === chat.owner.id || this.loginService.isAdmin()) {
            return true;
        }
        for (const user of chat.users) {
            if (user.id === current.id) {
                return user.role === 'moderator';
            }
        }

        for (const group of chat.groups) {
            if (this.isUserInGroup(current, group)) {
                return group.role === 'moderator';
            }
        }

        return false;
    }
}
