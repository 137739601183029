<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">Ajouter une pièce jointe</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="info">
        Vous pouvez importer n'importe quel fichier, s'il n'est pas bloqué par notre antivirus.<br />
        Import <strong>limité à 20Mo</strong>
    </div>
    <div class="files-list">
        <div class="content">
            <div class="file" *ngFor="let file of files; let i = index">
                <span class="icon icon-document"></span>
                <span class="name">{{ file.name }}</span>
                <span class="icon-close" (click)="deleteFile(i)"></span>
            </div>
        </div>
    </div>
    <div class="upload-container">
        <div class="upload-button" (click)="uploadFile()">Ajouter un fichier</div>
        <input
            type="file"
            #fileElement
            style="display: none"
            (change)="onFilesAdded($event.target.files)"
        />
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="add-button" [ngClass]="{ disabled: isDisabled() }" (click)="addFilesToPost()">
        Joindre au message
        <div></div>
    </div>
</div>
