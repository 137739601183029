import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CarouselComponent } from './components/carousel/carousel.component';
import { ChatComponent } from './components/main/chat/chat.component';

const appRoutes: Routes = [
    {
        path: 'chat/:id',
        component: ChatComponent
    },
    {
        path: '',
        component: CarouselComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
