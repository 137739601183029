<div class="header">
    <span class="icon-Messages"></span>
    <span class="label" [innerHTML]="headerText"></span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <ng-container *ngIf="isLoaded">
        <div class="content-header">
            <span class="chats-count"
                ><b>{{ chats?.length }}</b> discussions</span
            >
            <span class="info-message"
                >Ces discussions sont celles auxquelles vous et
                {{ isGroup ? 'ce groupe' : 'cet utilisateur' }} participez. Vous les trouverez dans
                votre liste de discussions en cours</span
            >
        </div>
        <div class="top">
            <span class="name">Participants</span>
            <span class="author">Date du dernier message</span>
        </div>
        <div class="chat-container">
            <app-shared-chat-entry
                *ngFor="let chat of chats"
                [chat]="chat"
                (click)="openSharedChat(chat)"
            >
            </app-shared-chat-entry>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoaded">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="openCreateChat()">Créer un nouveau chat</div>
</div>
